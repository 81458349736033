import React, {useEffect, useState} from "react";
import "../css/explore.css";
import LoadScreen from "../../others/components/loadScreen";
import BackBtn from "../../others/components/backBtn";
import Footer from "../../partials/components/Footer";
import {NewContainer} from "./news-container";

const Explore = () => {
  const [loadState, setLoadState] = useState(false);
  useEffect(() => {
    setLoadState(true);
  }, []);
  return (
    <>
      <BackBtn />
      {loadState ? <LoadScreen /> : ""}
      <div className="explore-container">
        <div className="explore-hero">
          <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/Explore/exploreicon.png" alt="Icon" />
          <h1>News & Articles</h1>
        </div>
        <NewContainer
          date="August 28, 2024"
          title="Century Properties and PHirst honors Olympic champion Carlos Yulo with House & Lot Handover Ceremony"
          link="https://www.century-properties.com/carlos-yulo-house-and-lot-handover-ceremony/">
          <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/Explore/Img%201%20August%2028%202024.jpg"></img>
          Listed developer Century Properties Group (CPG) and its first-home brand PHirst celebrated National Heroes Day with a ceremonial handover of a house and lot package to Olympic double gold
          medalist Carlos Yulo at Century City Mall, Makati.
          <br />
          <br />
          CPG and PHirst executives presented Yulo with the symbolic key to his new home at PHirst Editions Batulao in Batangas before an enthusiastic crowd of about 300 CPG and PHirst officers,
          employees, and guests. Yulo’s new house, named Charles (the Spanish translation of Carlos), is a single-attached home with a 70 sqm floor area on a 136 sqm corner lot worth Php 6 million. It
          features a spacious living, dining, and kitchen area, as well as 3 bedrooms, 2 bathrooms, and a 2-car carport. This will be completed by March 2025.
          <br />
          <br />
          “It is an honor for us at Century Properties and PHirst to celebrate this moment with Carlos and all of you. Winning gold in the Olympics is an extraordinary achievement for the Philippines,
          and a double gold is historic for us, Filipinos. Carlos, we are very proud of you,” said CPG Executive Chairman Amb. Jose E.B. Antonio. “On behalf of CPG and PHirst, we present this gift to
          Carlos as a symbol of our gratitude and appreciation for his remarkable accomplishment.”
          <br />
          <br />
          <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/Explore/Img%202%20August%2028%202024.jpg"></img>
          “Winning an Olympic medal is an immense achievement, and the journey to the Olympics is even more challenging. Carlos, thank you for your hard work, passion, and dedication, and for
          inspiring us all,” said CPG President and CEO Marco Antonio. “At Century Properties, we share the values of Passion and Innovation that have driven Carlos to success. For over 38 years,
          these values have allowed us to create new generation real estate and support first-home buyers through PHirst Park Homes.”
          <br />
          <br />
          “Century Properties will continue to support the ‘New Generation of Olympians’ through the Gymnastics Association of the Philippines. We hope that our support will help young Filipino
          athletes achieve Olympic success like Carlos,” Antonio added.
          <br />
          <br />
          Yulo, the first Filipino and Southeast Asian to win two Olympic gold medals, attended the ceremony with his mentor, Gymnastics Association of the Philippines President Cynthia Carrion.
          Carrion has supported Yulo since his teens and throughout his Olympic journey. In addition to the house and lot package, CPG donated Php 500,000 to the Gymnastics Association of the
          Philippines.
          <br />
          <br />
          <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-corporate/images/Explore/Img%203%20August%2028%202024.jpg"></img>
          <br />
          <br />
          Carrion expressed her gratitude: “We are thankful to Joey (Amb. Jose E.B. Antonio), Century Properties, and PHirst for their generosity. This donation will greatly benefit the grassroots
          gymnasts I work with—about a hundred young athletes aspiring to achieve greatness like Carlos. Thank you once again.”
          <br />
          <br />
          The event, hosted by PHirst’s long-time ambassador Robi Domingo, also featured a brief interview with Yulo. When asked to share a message, Yulo said, “Ito na ‘yun. Nagawa natin. Naging
          motivation ko ‘iyong pangarap ko [na mag gold sa Olympics], at nagpapasalamat ako na hindi ako sumuko at patuloy na ginabayan ni Lord. Nagpapasalamat ako sa disiplina na mag-train araw-araw
          kahit minsan pakiramdam gusto ko nang sumuko. Hindi ito nagtatapos dito. Tuloy-tuloy tayong mangangarap at lalaban.” [This is it. We made it. My dream [to win gold in the Olympics] motivated
          me, and I am thankful that I did not give up and lost faith in God. I’m thankful for the discipline to train every day, even when I felt like giving up. It doesn’t end here. We will continue
          to dream and fight.”]
          <br />
          <br />
          The ceremony concluded with the handover of Yulo’s PHirst house and lot unit, led by CPG Executive Chairman Jose E.B. Antonio, CPG Vice Chairman John Victor R. Antonio, CPG President & CEO
          Marco Antonio, PHirst Executive VP and COO Loren Sales, PHirst CFO Rodel Marqueses, PHirst Assistant VP for Business Development and Project Development Arnel Eusebio, and PHirst VP for
          Technical and Operations Group Roy Lachica.
          <br />
          <br />
          In a similar gesture, CPG and PHirst awarded a house and lot to the Philippines’ first Olympic gold medalist, Hidilyn Diaz, in 2021, honoring her victory in weightlifting at the Tokyo 2020
          Olympics.
          <br />
          <br />
          PHirst continues to lead the first-home buyer market with master-planned communities featuring the 4Cs: Complete and well-provisioned homes, Conceptive amenities for a healthy lifestyle,
          Connected living through WiFi zones and shuttle services, and a Convenient buying experience. PHirst currently has 23 communities across Luzon and Visayas, with more developments underway.
          For more information, visit{" "}
          <a href="https://www.century-phirst.com/" className="social-link" target="_blank">
            www.century-phirst.com
          </a>
          .
        </NewContainer>

        <NewContainer
          date="August 7, 2024"
          title="Century Properties Group’s PHirst rewards Olympic Double Gold Medalist Carlos Yulo with House & Lot Package"
          link="https://www.century-properties.com/cpg-rewards-carlos-yulo/">
          PHirst, the First-Home brand of listed developer Century Properties Group (CPG), has announced that it will award a house and lot package to Carlos Yulo for his outstanding achievement in
          winning two gold medals in gymnastics at the 2024 Paris Olympics.
          <br />
          <br />
          “On behalf of PHirst and the entire Century Properties Group, we extend our sincerest gratitude and heartfelt congratulations to Carlos Yulo for his historic double gold in the Olympics,
          bringing pride to the Philippines,” said Amb. Jose E.B. Antonio, Executive Chairman of CPG. “We also extend our congratulations to Cynthia Carreon, President of the Gymnastics Association of
          the Philippines for the stewardship and guidance of Carlos Yulo all through the years.”
          <br />
          <br />
          CPG is presenting Yulo with a house and lot at its PHirst Editions Batulao project in Nasugbu, Batangas. The single attached home, coincidentally named Charles – translating to Carlos in
          Spanish – is valued at Php6.0 million.
          <br />
          <br />
          “We at PHirst celebrate Yulo’s triumph. His story represents the journey of every Filipino toward their dreams. We are honored to offer Carlos a ‘home of many firsts’ and wish him continued
          success as he pursues his dreams and creates more memorable moments both on a professional and personal level,” remarked Ricky M. Celis, President of PHirst Park Homes, Inc. and Century
          PHirst Corporation.
          <br />
          <br />
          “Carlos started training at the age of seven and has relentlessly pursued his dream every day since. He embodies grit and perseverance. We celebrate his remarkable achievement of winning two
          gold medals at the Olympics, which has not only brought glory to our nation but also inspired countless Filipinos,” said Marco R. Antonio, President and CEO of Century Properties Group.
          <br />
          <br />
          This gesture marks the second time that CPG has awarded a house and lot to a Filipino Olympic gold medalist. In 2021, CPG also honored Hidilyn Diaz with a PHirst house and lot package for
          her remarkable accomplishments as the Philippines’ first-ever Olympic gold medalist in Tokyo 2020.
          <br />
          <br />
          “We have been consistent with our desire to reward our outstanding Olympians. It started with the champion weightlifter Hidilyn Diaz who won our first-ever Olympic gold, and now we are once
          again delighted to gift Carlos a PHirst home, a ‘Home of Champions!’” Amb. Antonio adds. PHirst has captured the first-home buyer market with its master planned communities bearing the 4Cs:
          Complete and well-provisioned homes with a perimeter fence and gate, Conceptive amenities that promote a healthy and holistic lifestyle, Connected living through WiFi zones and a shuttle
          service, and a Convenient buying experience. PHirst currently has 23 communities and counting spread out in key growth areas in Luzon and Visayas. For more information visit the official{" "}
          <a href="https://century-phirst.com/" className="social-link" target="_blank">
            website
          </a>{" "}
          and its{" "}
          <a href="https://www.facebook.com/PHirstOfficialFacebookPage" className="social-link" target="_blank">
            Facebook
          </a>{" "}
          page.
        </NewContainer>

        <Footer />
      </div>
    </>
  );
};

export default Explore;

import React from "react";

export const NewContainer = (props) => {
  return (
    <div className="explore-content">
      <div className="article-content">
        <div className="article-date">
          <b>{props.date}</b>
        </div>
        <h1>{props.title}</h1>
        <div className="children-content">{props.children}</div>
        <div className="read-more">
          <a href={props.link} target="_blank">
            Read more
          </a>
        </div>
      </div>
    </div>
  );
};
